import { NextRouter } from 'next/router';
import { useEffect } from 'react';

import { setHeadBaseUrl } from '@otovo/shared/components/Head/headContext';
import { useAnalytics } from '@otovo/shared/hooks/useAnalytics';
import resolveTagManagerID from '@otovo/shared/lib/analytics/resolveTagManagerID';
import { handleCookieKey } from '@otovo/shared/lib/cookieUtils';
import GoogleTagManagerAndOneTrustScripts from '@otovo/shared/lib/GoogleTagManagerAndOneTrustScripts';

import '@otovo/shared/styles/globals.css';
import workaroundBrowserExtensions from '@otovo/shared/utils/workaroundBrowserExtensions';
import { storeUtmData } from '../lib/storeUtmData';

type Props = {
  Component: any;
  pageProps: any;
  router: NextRouter;
  err: any;
};

/**
 * Next.js uses the App component to initialize pages.
 * You can override it and control the page initialization. Which allows you to do amazing things like
 *  - Persisting layout between page changes
 *  - Keeping state when navigating pages
 *  - Custom error handling using componentDidCatch
 *  - Inject additional data into pages
 *  - Add global CSS
 */

function StorefrontApp({ Component, pageProps, router, err }: Props) {
  const { query, asPath } = router;
  const { config } = pageProps;

  const gtmId = resolveTagManagerID(config);

  // Init analytics before using it.
  useAnalytics({ path: asPath, isGTMEnabled: !!gtmId });

  useEffect(() => {
    (async () => {
      handleCookieKey();
    })();
  }, []);

  useEffect(() => {
    if (/Chrom/.test(window.navigator.userAgent)) {
      workaroundBrowserExtensions();
    }
  }, []);

  // UTM data is set based on the query params and utm data from Sanity
  // If there is no Sanity document, the utm data will be set based on the query params only
  const { sanityDocument } = pageProps;
  const { utmSource, utmCampaign, utmContent } = sanityDocument || {};
  useEffect(() => {
    storeUtmData({
      utmSource,
      utmCampaign,
      utmContent,
      searchParams: query,
    });
  }, [utmSource, utmCampaign, utmContent, query]);

  setHeadBaseUrl(config?.STOREFRONT_URL);

  return (
    <>
      {!!gtmId && (
        <GoogleTagManagerAndOneTrustScripts
          googleTagManagerId={gtmId}
          query={asPath.split('?')[1]}
        />
      )}
      <Component {...pageProps} err={err} />
      <div id="modal-root" />
    </>
  );
}

export default StorefrontApp;
