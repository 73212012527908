import { EventType } from '../../../types/analytics';
import { Cloud$ShoppingCartFinancingOption } from '../../../types/cloudApi';
import { Otovo$Config } from '../../../types/otovoweb';

export const qualifiedInterestEvent: EventType = {
  event: 'Solar Qualified Activation',
  eventCategory: 'Solar',
  eventAction: 'Qualified Activation',
  eventLabel: 'Post Activation',
};

export const newInterestEvent: EventType = {
  event: 'Solar Interest',
  eventCategory: 'Solar',
  eventAction: 'Interest',
  eventLabel: 'Lead',
};

export type GetActivationEventArgs = {
  interestId: string;
  financingOption: Cloud$ShoppingCartFinancingOption | undefined;
  config: Otovo$Config;
};
export function getActivationEvent({
  interestId,
  financingOption,
  config,
}: GetActivationEventArgs): Record<string, string> {
  const { matchedPartner } = config.BU_CONFIG;
  if (
    matchedPartner &&
    matchedPartner.type === 'AFFILIATE' &&
    matchedPartner.config.name === 'Segugio.it' &&
    financingOption
  ) {
    return {
      event: 'purchase',
      value: `${financingOption.total_gross_price}`,
      id: interestId,
    };
  }
  return {
    event: 'Solar Activation',
    eventCategory: 'Solar',
    eventAction: 'Activation',
    eventLabel: 'Lead',
  };
}

export const saleEvent: EventType = {
  event: 'Customer Purchase',
  eventCategory: 'Solar',
  eventAction: 'Sale',
  eventLabel: 'Purchase',
};

export const calculationShownEvent: EventType = {
  event: 'Calculation Shown',
  eventCategory: 'Solar',
  eventAction: 'Calculation',
  eventLabel: 'Lead',
};

export const contactMeEvent: EventType = {
  event: 'Solar Activation Form',
  eventCategory: 'Solar',
  eventAction: 'Form',
  eventLabel: 'Lead',
};

export const surfaceSubmittedEvent: EventType = {
  event: 'Surface submitted',
  eventCategory: 'Custom Surface UI',
  eventAction: 'Submit Surface Succeeded',
  eventLabel: 'Custom Surface Submit',
};
