import { stripURL } from '../stripUrl';

import { EventType } from '../../types/analytics';
import { broadcastEvent } from '../iframeAPI';
import { CaptureEvent, capturePostHogEvent } from '../posthog/captureEvent';
import {
  dataLayerPageView as gtmDataLayerPageView,
  dataLayerPush as gtmDataLayerPush,
} from './gtm';
import {
  GetActivationEventArgs,
  calculationShownEvent,
  contactMeEvent,
  getActivationEvent,
  newInterestEvent,
  qualifiedInterestEvent,
  saleEvent,
  surfaceSubmittedEvent,
} from './gtm/eventPayload';
import { logTagCommanderEvent } from './tagCommanderEvents';

let gtmEnabled = null;

/*
  Track page views to GA.

  Facebook automatically tracks changes to page views so the track function in
  fb.js should not be called. We would otherwise track double page views.
  A caveat is that the Facebook pixel requires the URL to change to track a page
  view. Read more at https://developers.facebook.com/ads/blog/post/v2/2017/05/29/tagging-a-single-page-application-facebook-pixel/
*/
export function logPageView(href: string) {
  const stripped = stripURL(href);
  if (!href.startsWith('/')) {
    throw new Error(`href's must start with '/'. Was ${stripped}`);
  }

  if (gtmEnabled) {
    gtmDataLayerPageView(stripped);
  }
}

const loggedEvents = [];

function isLogged(logKey: string): boolean {
  return loggedEvents.includes(logKey);
}

function markAsLogged(logKey: string) {
  loggedEvents.push(logKey);
}

export function logVirtualPageView(pagePath: string, once = false) {
  if (!gtmEnabled) {
    return;
  }

  if (once && isLogged(pagePath)) {
    return;
  }

  markAsLogged(pagePath);
  gtmDataLayerPageView(pagePath);
}

type Options = {
  once?: boolean;
  broadcastToParent?: boolean;
};

/**
 * Logs a user behavior event.
 *
 * Use 'action' to indicate what happened, for example:
 *   "User zoomed in on the map in the custom surface flow"
 *
 * Use `context` if you send the same action with multiple contexts; For example if a
 * component is placed more than one place, and you'd like to distinguish that
 * in the event.
 *
 * Event 1:
 *   action: 'User zoomed in on the map'
 *   context: 'Custom surface flow'
 *
 * Event 2:
 *   action: 'User zoomed in on the map'
 *   context: 'Calculation page'
 *
 * @param {Object} params - The parameters for the event.
 * @param {string} params.action - The action performed by the user.
 * @param {string} [params.context=''] - The context in which the action was performed.
 * @param {Options} [options] - Additional options for logging the event.
 * @param {boolean} [options.once=false] - If true, the event is logged only once for a unique combination of category, action, and label.
 * @param {boolean} [options.broadcastToParent=false] - If true, the event is broadcasted to the parent context.
 *   Important: Do not use this option with sensitive data like prices or personally identifiable information (PII) to prevent potential data exposure.
 */
export function logUIEvent(
  { action, context = '' }: { action: string; context?: string },
  options?: Options,
) {
  const { once = false, broadcastToParent = false } = options || {};
  const category = 'UI';
  const label = context;

  const logKey = `${category}-${action}-${label || ''}`;
  if (once && isLogged(logKey)) {
    return;
  }

  if (once) {
    markAsLogged(logKey);
  }

  if (broadcastToParent) {
    broadcastEvent({ type: 'USER_EVENT', action, debug: false });
  }

  if (gtmEnabled) {
    gtmDataLayerPush({
      event: 'UI events',
      eventCategory: category,
      eventAction: action,
      eventLabel: context,
    });
  }
}

function logQualifiedInterest() {
  if (gtmEnabled) {
    gtmDataLayerPush(qualifiedInterestEvent);
  }
}

export function qualifyUserFromPostActivation(market: string, value: string) {
  const excludingConstraints = {
    it: ['it_ecobonus_110_with_paperwork'],
    es: ['apartment'],
    de: ['renter'],
  };
  if (!excludingConstraints[market]) {
    return;
  }

  if (excludingConstraints[market].find((contraint) => contraint === value)) {
    return;
  }

  logQualifiedInterest();
}

export function logInterest({ locale }) {
  capturePostHogEvent({ eventName: CaptureEvent.INTEREST_CREATED, locale });

  logUIEvent({
    action: 'User created an interest',
  });

  logTagCommanderEvent(newInterestEvent);
  if (gtmEnabled) {
    gtmDataLayerPush(newInterestEvent);
  }
}

function isEventType(event: Record<string, string>) {
  return (
    typeof event.event === 'string' &&
    typeof event.eventCategory === 'string' &&
    typeof event.eventAction === 'string' &&
    typeof event.eventLabel === 'string'
  );
}

export function logActivation({
  interestId,
  financingOption,
  config,
}: GetActivationEventArgs) {
  capturePostHogEvent({
    eventName: CaptureEvent.INTEREST_ACTIVATED,
    locale: config.BU_CONFIG.locale,
  });

  logUIEvent({
    action: 'User activated an interest',
  });

  const activationEvent = getActivationEvent({
    interestId,
    financingOption,
    config,
  });

  if (isEventType(activationEvent)) {
    logTagCommanderEvent(activationEvent as EventType);
  }
  if (gtmEnabled) {
    gtmDataLayerPush(activationEvent);
  }
}

export function logSale() {
  logUIEvent({
    action: 'User proceeded to signing the offer',
  });
  if (gtmEnabled) {
    gtmDataLayerPush(saleEvent);
  }
}

export function logCalculationShown() {
  logTagCommanderEvent(calculationShownEvent);
  if (gtmEnabled) {
    gtmDataLayerPush(calculationShownEvent);
  }
}

export function logContactMe() {
  logTagCommanderEvent(contactMeEvent);

  if (gtmEnabled) {
    gtmDataLayerPush(contactMeEvent);
  }
}

export function logSurfaceSubmitted() {
  logUIEvent({
    action: 'User successfully submitted surface',
  });

  if (gtmEnabled) {
    gtmDataLayerPush(surfaceSubmittedEvent);
  }
  logTagCommanderEvent(surfaceSubmittedEvent);
}

type AnalyticsConfig = {
  GOOGLE_TAG_MANAGER_ENABLED: boolean;
};

let initialized = false;
export function initAnalytics(analyticsConfig: AnalyticsConfig) {
  if (!__isBrowser__ || initialized) {
    // We only support client side analytics
    return;
  }
  initialized = true;

  gtmEnabled = analyticsConfig.GOOGLE_TAG_MANAGER_ENABLED;
}
